<template>
  <div class="layout-root">
    <div
      v-if="theme === 'edition'"
      class="background"
      @click="navigateToLegalReportPage"
    />

    <div class="layout-root__actions">
      <ReflectScopeButton
        v-if="selectedLegalReportRealizedPeriod"
        icon="icon-date-range"
        :label="selectedLegalReportRealizedPeriod"
      />
      <ReflectScopeButton
        :label="
          selectedLegalReportFilterFormattedValue ||
          $t(`sharedAnalytics.scope.toggle.COMPANY`)
        "
      />
      <div
        id="header-action-button"
        class="additional-header-action-button"
      />
    </div>

    <slot v-if="availableLegalReports" />
    <Loader v-else />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import type { SlotsType } from 'vue'
import { useCubeStore } from '~/stores/cube'
import { useLegalReportsStore } from '~/stores/legalReports'
import { useLayoutStore } from '~/stores/layout'
import { getFormattedFilterValues } from '~/services/filters'
import {
  getLegalReportRealizedPeriod,
  getMainLegalReportFilter
} from '~/services/legalReports/main'
import { QUERY_PARAMETERS } from '~/types/queryParameters'
import { formatDate2 } from '~/services/date'
import { navigateToWithQueryParams } from '~/services/router'
import { ROUTE } from '~/constants/routes'

export default {
  name: 'LegalReportsLayout',
  slots: Object as SlotsType<{
    default: {}
  }>,
  computed: {
    ...mapState(useLayoutStore, ['theme']),
    ...mapState(useLegalReportsStore, ['availableLegalReports', 'legalReport']),
    selectedLegalReportFilterFormattedValue() {
      return this.legalReport
        ? getFormattedFilterValues(
            getMainLegalReportFilter(this.legalReport.legalReportFilters)
          )
        : null
    },
    selectedLegalReportRealizedPeriod() {
      if (!this.legalReport) {
        return null
      }

      const [startDate, endDate] = getLegalReportRealizedPeriod(
        this.legalReport
      )
      return `${formatDate2(startDate)} - ${formatDate2(endDate)}`
    }
  },
  async created() {
    const cubeStore = useCubeStore()
    await cubeStore.loadMeta()
    await useLegalReportsStore().getAvailableLegalReports()
  },
  methods: {
    async navigateToLegalReportPage() {
      if (this.legalReport) {
        await navigateToWithQueryParams({
          path: `${ROUTE.LEGAL_REPORTS}/${this.legalReport.id}`,
          queryParamsToKeep: [QUERY_PARAMETERS.LEGAL_REPORT_SECTION_ID]
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  padding-top: $margin-regular;
  padding-bottom: $margin-medium;
  box-sizing: border-box;

  &__actions {
    z-index: 3;
    position: absolute;
    right: calc(50% - $page-width / 2);
    height: 54px;
    display: flex;
    align-items: center;
    gap: $margin-small;
  }
}

.background {
  position: fixed;
  top: 0;
  z-index: 2;
  height: 100%;
  width: calc(100% + $side-width);
  background-color: $overlay-background;
  cursor: pointer;
}

.additional-header-action-button {
  &:empty {
    display: none;
  }
}
</style>
